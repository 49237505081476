import React from "react"
import { graphql } from "gatsby"
import Layout from "components/layout"
import { Heading } from "../components/Hero"
import { Container, Section } from "../components/Layouts"
import SEO from "components/seo"
import Youtube from "../components/Youtube"
import { ContactForm } from "../components/Forms"

const PlasticSurgeryMarketingRevolutionPage = ({ data, path }) => {
  const post = data.allUniquePagesJson.nodes[0]

  return (
    <Layout className="psmr-page" c path={path}>
      <SEO title={post.metaTitle} description={post.metaDescription} />

      <Heading className="has-text-centered" content={post.heading} />

      <Section className="relative">
        <Container>
          <div className="columns">
            <div className="column">
              <Youtube code="IByzyHngSCo" />
            </div>
          </div>
        </Container>
      </Section>

      <Section zeroBottom className="relative psmr-page__main-section">
        <Container className="psmr-page__form-section">
          <div>
            <ContactForm classNames="mt-2" redirect="/thank-you/" />
          </div>
        </Container>
      </Section>
    </Layout>
  )
}

export default PlasticSurgeryMarketingRevolutionPage

export const pageQuery = graphql`
  query plasticSurgeryMarketingRevolution($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        metaTitle
        metaDescription
        heading
        body
        image
      }
    }
  }
`
